<template>
  <div class="detail positionr" v-loading="loading">
    <!-- 面包屑导航区 -->

    <!-- 内容主体 -->
    <div class="bgwhite bort">
      <el-row>
        <el-col :span="21">
          <div class="menu-content">
            <!-- 基本信息 -->
            <div class="p1" id="a1">
              <div class="header-search">
                <div class="title">
                  <el-row>
                    <el-col :span="12">
                      <span class="line"></span>
                      基本信息
                    </el-col>
                    <el-col :span="12">
                      <div class="flex align-center justify-end h50">
                        <el-tooltip
                          class="item"
                          effect="dark"
                          content="保存基本信息和扩展属性"
                          placement="bottom"
                        >
                          >
                        </el-tooltip>
                        <el-tooltip
                          class="item"
                          effect="dark"
                          content="返回列表"
                          placement="bottom"
                        >
                          <el-button @click="goIndex()" size="small"
                            >返回</el-button
                          >
                        </el-tooltip>
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </div>
              <div class="header-input">
                <el-form
                  :model="baby"
                  ref="baby"
                  label-width="150px"
                  class="baby"
                  size="mini"
                >
                  <el-row>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="发布人" prop="createBy">
                        <el-input v-model="baby.createBy" readonly="true"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="发布内容" prop="content">
                        <!-- <el-input v-model="baby.content"></el-input> -->
                        <el-input type="textarea" v-model="baby.content" readonly="true"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="用户头像" prop="createLogo">
                        <div style="border-radius:50%;width: 120px; height:120px;background:lightpink;overflow: hidden;">
                            <el-image
                          :src="baby.createLogo"
                          style="width: 100%; height: 100%;"
                        />
                        </div>
                       
                      </el-form-item>
                      <el-form-item label="发布附件" prop="babyEnclosureList">
                        <div
                          class="item mr30 ml40 mb40"
                          style="padding-top:3%;width:100%;"
                        > 
                        <div style="width: 150px; height:110px;display:inline-block;margin-left:2%;"
                             v-for="(item, index) in baby.babyEnclosureList"
                          :key="index">
                          <el-image
                            style="width: 100%; height: 100%;"
                            :src="item.url"
                          />
                          </div>
                        </div>
                      </el-form-item>
                    </el-col>
                    <!-- <el-col :sm="24" :md="24" :lg="12"> -->
                      <!-- <el-form-item label="发布附件" prop="babyEnclosureList">
                        <div
                          class="item mr30 ml40 mb40"
                          style="padding-top:3%;"
                        > 
                        <div style="width: 220px; height:160px;display:inline-block;margin-left:2%;"
                             v-for="(item, index) in baby.babyEnclosureList"
                          :key="index">
                          <el-image
                            style="width: 100%; height: 100%;"
                            :src="item.url"
                          />
                          </div>
                        </div>
                      </el-form-item> -->
                      <!-- <el-dialog :visable.sync="dialogVisible"
                                  :destroy-on-close="true"
                                  @close="">
                         <player :video="video" v-if="playerVisible"> <player>   
                      </el-dialog> -->
                     <!-- <vueMiniPlayer  
                        v-show="showGroup"
                        src="url"
                        style="width: 60%;height:40%;margin-left:21%;"
                         /> -->
                    <!-- </el-col> -->
                  </el-row>
                </el-form>
                <div style="margin-left:70%;margin-top:5%;" v-show="isShow">
                <el-button type="primary" size="small" @click="pass()"
                  >通过</el-button
                >
                <el-button type="primary" size="small" @click="dialogFormVisible = true"
                  >拒绝</el-button>
                  </div>
              </div>
            </div>
             <!-- 弹窗 -->
              <el-dialog
                title="拒绝通过"
                :visible.sync="dialogFormVisible"
              
              >
                <el-form >
                  <el-form-item               
                    label="拒绝理由"
                    style="margin-top: 15px;margin-left:10px;"
                  >
                    <el-input
                    type="textarea"
                      v-model="baby.refusal"
                      autocomplete="off"
                      style="width: 450px"
                    >
                    </el-input>
                  </el-form-item>                 
                </el-form>
                <div>
                  <!-- <span>提示：建议150字以内</span> -->
                </div>
                <div slot="footer" class="dialog-footer">
                  <el-button @click="dialogFormVisible = false"
                    >取 消</el-button
                  >
                  <el-button type="primary" @click="refuse()"
                    >确 定</el-button
                  >
                </div>
              </el-dialog>
            <!--  多功能 -->
            <!-- <BabyFunction></BabyFunction> -->

            <!-- 扩展-->
            <!-- <BabyExtends></BabyExtends> -->
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 当前页数
      currPage: 1,
      pageSize: 10,
      seeThis: 0,
      loading: true,
      id: "",
      baby: {
        id: "",
        createBy: "",
        createTime: "",
        updateBy: "",
        updateTime: "",
        babyCode: "",
        content: "",
        createLogo: "",
        likeCount: "",
        viewCount: "",
        refusal:'',
        babyEnclosureList: [],
      },
      showGroup:"true",
       dialogFormVisible: false,
       isShow:true,
    };
  },
  methods: {
    goIndex() {
      this.$router.push({
        path: "/baby",
        query: { currPage: this.currPage, pageSize: this.pageSize },
      });
    },
    pass(formName) {
      var that = this;
      var getData = {
        id: that.id,
        status: 1,
      };
      that.$http.post("/baby/save", getData).then(response => {
        if (response.data.code == 200) {
          that.$notify.success({
            title: "提示",
            message: "通过成功",
            showClose: true,
          })
           this.goIndex()
        }
      })
    },
    refuse() {
      var that = this;
      var getData = {
        id: that.id,
        status: 2,
        refusal:that.baby.refusal
      };
      that.$http.post("/baby/save", getData).then(response => {
        if (response.data.code == 200) {
          
          that.$notify.success({
            title: "提示",
            message: "已拒绝",
            showClose: true,
          });
           this.dialogFormVisible= false
           this.isShow=false
        }
      });
    },
    loadBaseInfo() {
      let that = this;
      if (this.id != undefined && this.id != "") {
        that.$http
          .post("/baby/queryOneFie", { id: this.id })
          .then(function (response) {
            if (response.data.code == 200) {
              that.total = response.data.data.count;
              that.baby = response.data.data;
              console.log('baby',response.data.data)
              that.baby.babyEnclosureList =
              response.data.data.babyEnclosureList;
              console.log("1111",that.baby.babyEnclosureList);
            }
             if (response.data.data.status==2) {
                that.isShow=false
              }
          });
      }
    },
    goAnchor(index) {
      // 也可以用scrollIntoView方法， 但由于这里头部设置了固定定位，所以用了这种方法
      // document.querySelector('#anchor'+index).scrollIntoView()
      this.seeThis = index;
      var i = index + 1;
      var anchor = this.$el.querySelector("#a" + i);
      this.$nextTick(() => {
        this.$el.querySelector(".menu-content").scrollTop = anchor.offsetTop;
      });
    },
    handleScroll() {
      //console.log("滚动了");
      var scrollTop = this.$el.querySelector(".menu-content").scrollTop;
      var scrollHeight = this.$el.querySelector(".menu-content").scrollHeight;
      var height = this.$el.querySelector(".menu-content").offsetHeight;
      var anchorOffset1 = this.$el.querySelector("#a1").offsetTop;
      var anchorOffset2 = this.$el.querySelector("#a2").offsetTop;
      var anchorOffset3 = this.$el.querySelector("#a3").offsetTop;

      if (scrollTop > anchorOffset1 && scrollTop < anchorOffset2) {
        this.seeThis = 0;
      }
      if (scrollTop > anchorOffset2 && scrollTop < anchorOffset3) {
        this.seeThis = 1;
      }
      if (scrollTop > anchorOffset3 && scrollTop + height > scrollHeight) {
        this.seeThis = 3;
      }

      if (scrollTop + height > scrollHeight) {
        this.seeThis = 3;
      }
    },
  },
  created() {
    if (this.$route.query.id != undefined && this.$route.query.id != "") {
      this.id = this.$route.query.id;
    }
    console.log('id',this.id)
    this.currPage = this.$route.query.currPage;
    this.pageSize = this.$route.query.pageSize;
    this.loadBaseInfo();
  },
  mounted() {
    var that = this;
    this.$el.querySelector(".menu-content").onscroll = () => {
      that.handleScroll();
    }; // 监听滚动事件，然后用handleScroll这个方法进行相应的处理
    that.loading = false;
  },
};
</script>

<style lang="less" scope>
.h50 {
  height: 50px;
}
.detail {
  .el-breadcrumb__inner {
    color: #2f91df;
  }
  .el-row {
    #navigation {
      background-color: #f6f7fb;
      font-size: 14px;
      height: calc(100vh - 110px);
      margin-top: -14px;
      li {
        height: 50px;
        line-height: 50px;
        a {
          display: block;
          color: #324059;
          padding-left: 20px;
          background-color: transparent;
        }
      }
      li.active,
      li:hover {
        background-color: #fff;
      }
    }
    .menu-content {
      height: calc(100vh - 110px);
      overflow-y: auto;
      padding-left: 10px;
      padding-right: 5px;
      .p1 {
        margin-bottom: 20px;
      }
      .header-search {
        .title {
          height: 50px;
          line-height: 49px;
          font-size: 15px;
          color: #2f405b;
          padding-left: 10px;
          border-bottom: 1px solid #e3eaf0;
          margin-bottom: 10px;
          .line {
            display: inline-block;
            width: 3px;
            height: 15px;
            background-color: #2f91df;
            margin-right: 5px;
            border-radius: 2px;
            margin-bottom: -2px;
          }
          .el-input {
            width: 247px;
          }
          .el-input .el-input__inner {
            width: 217px;
          }
        }
      }
      .header-input {
        font-size: 13px;
      }
      .model-mask {
        .el-form {
          .el-form-item {
            margin-bottom: 0;
          }
        }
        .el-table {
          margin-top: 10px;
        }
      }
    }
  }
  #selectorOrgDialog .el-dialog {
    width: 600px !important;
  }
  #selectorOrgDialog .selectedUl {
    height: 350px;
    max-height: 350px;
  }
  #selectorOrgDialog .org-selected .selectedUl-box {
    height: 320px !important;
  }
}
</style>
